import { reactive, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import DIContainer from "@/core/DIContainer";
import RoutePath from "@/commons/RoutePath";
import { useStore } from "vuex";
import AuthInteracter from "@/domain/usecases/AuthInteractor";
import { useForm } from "vee-validate";
export default defineComponent({
    name: "OrganizationRegisterInvitedCompleted",
    components: {
        Breadcrumb: Breadcrumb
    },
    setup: function () {
        var router = useRouter();
        var store = useStore();
        var _a = useForm(), isSubmitting = _a.isSubmitting, handleSubmit = _a.handleSubmit;
        var authInteractor = DIContainer.instance.get(AuthInteracter);
        var state = reactive({
            listBreadcrumb: [
                {
                    route: '/', name: "TOP"
                },
                {
                    route: '', name: "登録完了"
                }
            ],
        });
        var goOrganizationDashboard = handleSubmit(function () {
            return authInteractor.authCheck().then(function (result) {
                if (result.data && result.data && result.data.user) {
                    store.commit("setUserData", result.data.user);
                    var account_name = result.data.user.name;
                    var is_composition = result.data.user.is_composition;
                    localStorage.setItem("accountName", account_name);
                    localStorage.setItem("isCompositon", is_composition);
                }
                router.push(RoutePath.ORGANIZATION_DASHBOARD);
            }).catch(function (error) {
            });
        });
        onMounted(function () {
        });
        return {
            state: state,
            isSubmitting: isSubmitting,
            goOrganizationDashboard: goOrganizationDashboard
        };
    }
});
