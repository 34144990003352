<template>
    <div class="organization_register_completed">
        <div class="organization_register_completed__top">
            <div class="organization_register_completed__top__step">
                <span>
                    ログイン情報入力
                </span>
                <span>
                    担当者情報登録
                </span>
                <span>
                    登録内容確認
                </span>
                <span class="active">
                    ご利用登録完了
                </span>
            </div>
            <p class="organization_register_completed__top-title">
                ご利用登録完了
            </p>
        </div>
        <div class="organization_register_completed__body">
            <div class="mw-1000">
                <div class="organization_register_completed__body__form">
                    <form @submit="goOrganizationDashboard" class="horizontal">
                        <div
                            class="organization_register_completed__body__form-infomation"
                        >
                            <span>
                                担当者のご利用登録が完了しました。<br>
                                <br>
                                下記よりダッシュボードへ移動し、企業プロフィールをご入力ください。<br>
                                受講生チームを選択される前にご入力をお願いします。
                            </span>
                        </div>
                        <div class="btn_group">
                            <button

                                :disabled="isSubmitting"
                                :class="{ submitting: isSubmitting }"
                                type="submit"
                                class="btn-md btn-blue shadow"
                            >
                                <i v-if="isSubmitting" class="fas fa-circle-notch fa-spin"></i>
                                ダッシュボードへ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="@/presentation/views/organization/register-invited/completed/Completed.ts"></script>

<style lang="scss">
@import "@/presentation/views/organization/register-invited/completed/Completed.scss";
</style>
